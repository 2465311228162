<template>
    <div class="random">
        <div class="random-left">
            <div>
                <span class="title">利息计算器</span>
            </div>
            <el-row class="line">
                <el-col :span="24">
                    <table class="password-table">
                        <tr id="investment">
                            <td class="td1"><span>投资金额</span></td>
                            <td class="td2">
                                <el-input v-model="investment" @input="countIncome()" placeholder="请输入" maxlength="6"
                                          size="small"></el-input>
                            </td>
                            <td class="td3">
                                <el-radio-group v-model="investmentUnit" size="mini" @change="countIncome()">
                                    <el-radio-button label="万"></el-radio-button>
                                    <el-radio-button label="元"></el-radio-button>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr id="investTimeLimit">
                            <td class="td1"><span>投资期限</span></td>
                            <td class="td2">
                                <el-input v-model="investTimeLimit" @input="countIncome()" placeholder="请输入" maxlength="6"
                                          size="small"></el-input>
                            </td>
                            <td class="td3">
                                <el-radio-group v-model="investTimeUnit" size="mini" @change="setInvestAgainUnit()">
                                    <el-radio-button label="年"></el-radio-button>
                                    <el-radio-button label="月"></el-radio-button>
                                    <el-radio-button label="日"></el-radio-button>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr id="investCountMode">
                            <td class="td1"><span>计算方式</span></td>
                            <td class="td2" colspan="2">
                                <el-radio-group v-model="investCountMode" size="mini" @change="switchCountMode()">
                                    <el-radio-button label="单利"></el-radio-button>
                                    <el-radio-button label="复利"></el-radio-button>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr id="investAgain">
                            <td class="td1"><span>复利单位</span></td>
                            <td class="td2" colspan="2">
                                <el-radio-group v-model="investAgain" size="mini" @change="setInvestTimeUnit()">
                                    <el-radio-button label="年"></el-radio-button>
                                    <el-radio-button label="月"></el-radio-button>
                                    <el-radio-button label="日"></el-radio-button>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr id="investRate" ref="investRate">
                            <td class="td1"><span>收益率(%)</span></td>
                            <td class="td2">
                                <el-input v-model="investRate" @change="parseWanOfIncome()" placeholder="请输入" maxlength="6"
                                          size="small"></el-input>
                            </td>
                            <td class="td3">
                                <el-radio-group v-model="investRateUnit" size="mini" @change="parseWanOfIncome()">
                                    <el-radio-button label="年"></el-radio-button>
                                    <el-radio-button label="月"></el-radio-button>
                                    <el-radio-button label="日"></el-radio-button>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr id="investWanOfIncome">
                            <td class="td1"><span>万份收益</span></td>
                            <td class="td2" colspan="2">
                                <el-input v-model="investWanOfIncome" @change="parseRate()" placeholder="请输入" maxlength="6"
                                          size="small"></el-input>
                            </td>
                        </tr>
                        <tr id="investIncomeInterest" ref="investIncomeInterest">
                            <td class="td1"><span>获取利息(元)</span></td>
                            <td class="td2" colspan="2">
                                <el-input v-model="investIncomeInterest" @change="countRate()" placeholder="请输入" maxlength="6"
                                          size="small"></el-input>
                            </td>
                        </tr>
                        <tr id="investIncomeTotal">
                            <td class="td1"><span>获取总金额(元)</span></td>
                            <td class="td2" colspan="2">{{ investIncomeTotal }}</td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
            <el-row style="margin-top: 20px" v-if="adSwitch">
                <el-col :span="24" style="text-align: center">
                    <div class="ad" style="height: 90px;text-align: center;"></div>
                </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
                <el-col :span="24" style="text-align: center">
                    <el-image :src="pwd_img_url" width="95%" style="max-width: 550px;border-radius: 10px;"></el-image>
                </el-col>
            </el-row>
            <el-row class="about">
                <el-col :span="24">
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item title="关于利息计算器" name="1">
                            <div>
                                <p> 利息计算器是一种非常实用的工具，它可以帮助人们计算各种贷款、存款和投资的利息。通过输入相关的信息，比如贷款金额、利率、还款期限等，利息计算器可以快速地计算出每月需要还款的金额和总利息。同样地，对于存款和投资，利息计算器可以帮助人们预测未来的收益，并帮助人们做出更明智的决策。</p>
                                <p>  利息计算器的使用非常简单，只需要输入相关信息，就可以得到准确的结果。而且，利息计算器的使用范围非常广泛，不仅可以用于个人的贷款和投资，还可以用于企业的融资和投资。因此，利息计算器是一种非常实用的工具，可以帮助人们更好地管理自己的财务，做出更明智的决策。</p>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-row>
        </div>
        <div class="random-right">
            <div style=" height: 250px; margin-bottom: 8px; text-align: center; " v-if="adSwitch">

            </div>
            <div>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>推荐工具</span>
                    </div>
                    <div v-for="(item,index) in recommendList" :key="index" style="margin-bottom: 10px">
                        <a :href="item.url" target="_blank" style="color: #3a8ee6;">{{ item.name }}</a>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import Table from "@/views/Test.vue";

export default {
    components: {Table},
    metaInfo: {
        title: '利息计算器 - 免费在线利息计算工具',
        meta: [
            {
                charset: "utf-8"
            },
            {
                name: 'description,',
                content: '这是一款完全免费的在线利息计算器,可帮您快速计算投资本金、投资期限和收益率下的应得利息和总金额。无需注册,输入少量信息即可得出结果。方便实用,欢迎使用。'
            },
            {
                name: 'keywords',
                content: '利息计算器,在线利息计算工具,投资利息计算,本金利息计算,免费利息计算器'
            }],
        link: [
            {
                rel: 'canonical',
                href: 'https://www.itptg.com/invest/'
            }
        ]
    },
    data() {
        return {
            pwd_img_url: require("@/assets/images/lixi.jpg"),
            activeNames: ['1'],
            adSwitch: false,
            recommendList: [
                {'name': '随机密码生成', 'url': '/'},
                {'name': '利息计算器', 'url': '/invest/'},
                {'name': '成本计算器', 'url': '/stockcost/'},
                {'name': '编码转换', 'url': '/stockcost/'}
            ],

            investment: 0,
            investmentUnit: '万',
            investTimeLimit: 0,
            investTimeUnit: '年',
            investCountMode: '单利',
            investAgain: '日',
            investRate: 3,
            investRateUnit: '年',
            investWanOfIncome: 0,
            investIncomeInterest: 0,
            investIncomeTotal: 0,
        }
    },
    computed: {
        conversion() {
            let p, n, r;
            if (this.investmentUnit == '万') {
                p = this.investment * 10000;
            } else if (this.investmentUnit == '元') {
                p = this.investment;
            }
            if (this.investAgain == '年') {
                if (this.investRateUnit == '年') {
                    r = this.investRate;
                } else if (this.investRateUnit == '月') {
                    r = this.investRate * 12;
                } else if (this.investRateUnit == '日') {
                    r = this.investRate * 360;
                }
                if (this.investTimeUnit == '年') {
                    n = this.investTimeLimit;
                }
            } else if (this.investAgain == '月') {
                if (this.investRateUnit == '年') {
                    r = this.investRate / 12;
                } else if (this.investRateUnit == '月') {
                    r = this.investRate;
                } else if (this.investRateUnit == '日') {
                    r = this.investRate * 30;
                }
                if (this.investTimeUnit == '年') {
                    n = this.investTimeLimit * 12;
                } else if (this.investTimeUnit == '月') {
                    n = this.investTimeLimit;
                }
            } else if (this.investAgain == '日') {
                if (this.investRateUnit == '年') {
                    r = this.investRate / 360;
                } else if (this.investRateUnit == '月') {
                    r = this.investRate / 30;
                } else if (this.investRateUnit == '日') {
                    r = this.investRate;
                }
                if (this.investTimeUnit == '年') {
                    n = this.investTimeLimit * 360;
                } else if (this.investTimeUnit == '月') {
                    n = this.investTimeLimit * 30;
                } else if (this.investTimeUnit == '日') {
                    n = this.investTimeLimit;
                }
            }
            return {p, n, r};
        },
    },
    methods: {
        handleChange(val) {
            console.log(val);
        },
        setInvestAgainUnit() {
            if (this.investCountMode == '单利') {
                this.investAgain = this.investTimeUnit;
            } else if (this.investCountMode == '复利') {
                if (this.investTimeUnit == '月') {
                    if (this.investAgain == '年') {
                        this.investAgain = '月';
                    }
                } else if (this.investTimeUnit == '日') {
                    if (this.investAgain == '年' || this.investAgain == '月') {
                        this.investAgain = '日';
                    }
                }
            }
            this.countIncome();
        },
        setInvestTimeUnit() {
            if (this.investAgain == '年') {
                if (this.investTimeUnit == '月' || this.investTimeUnit == '日') {
                    this.investTimeUnit = '年';
                }
            } else if (this.investAgain == '月') {
                if (this.investTimeUnit == '日') {
                    this.investTimeUnit = '月';
                }
            }
            this.countIncome();
        },
        countIncome() {
            if (this.investment > 0 && this.investTimeLimit > 0 && (this.investRate > 0 || this.investWanOfIncome > 0)) {
                let conver = this.conversion;
                let p = conver.p;	//投资本金
                let n = conver.n;	//投资期数
                let r = conver.r; 	//投资利率
                if (this.investCountMode == '单利') {
                    this.investIncomeInterest = this.approach(p * (r * 0.01) * n, 100);
                    this.investIncomeTotal = p + this.investIncomeInterest;
                } else if (this.investCountMode == '复利') {
                    this.investIncomeTotal = this.approach(p * Math.pow(1 + r * 0.01, n), 100);
                    this.investIncomeInterest = this.approach(this.investIncomeTotal - p, 100);
                }
            }
        },
        countRate() {
            if (this.investment > 0 && this.investTimeLimit > 0 && this.investIncomeInterest > 0) {
                let conver = this.conversion;
                let p = conver.p;	//投资本金
                let n = conver.n;	//投资期数
                let r = 0;
                this.investIncomeTotal = parseFloat(p) + parseFloat(this.investIncomeInterest);
                if (this.investAgain == '年') {
                    if (this.investRateUnit == '年') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) * 100;
                    } else if (this.investRateUnit == '月') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) / 30 * 100;
                    } else if (this.investRateUnit == '日') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) / 360 * 100;
                    }
                } else if (this.investAgain == '月') {
                    if (this.investRateUnit == '年') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) * 12 * 100;
                    } else if (this.investRateUnit == '月') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) * 100;
                    } else if (this.investRateUnit == '日') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) / 30 * 100;
                    }
                } else if (this.investAgain == '日') {
                    if (this.investRateUnit == '年') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) * 360 * 100;
                    } else if (this.investRateUnit == '月') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) * 30 * 100;
                    } else if (this.investRateUnit == '日') {
                        r = (Math.exp(Math.log(this.investIncomeTotal / p) / n) - 1) * 100;
                    }
                }
                this.investRate = this.approach(r, 10000);
                this.countWanOfIncome();
            }
        },
        countWanOfIncome() {
            if (this.investRate > 0) {
                if (this.investRateUnit == '年') {
                    this.investWanOfIncome = this.approach(this.investRate * 0.01 * 10000 / 360, 10000);
                } else if (this.investRateUnit == '月') {
                    this.investWanOfIncome = this.approach(this.investRate * 0.01 * 10000 / 30, 10000);
                } else if (this.investRateUnit == '日') {
                    this.investWanOfIncome = this.approach(this.investRate * 0.01 * 10000, 10000);
                }
            }
        },
        parseWanOfIncome() {
            this.countWanOfIncome();
            this.countIncome();
        },
        parseRate() {
            if (this.investWanOfIncome > 0) {
                if (this.investRateUnit == '年') {
                    this.investRate = this.approach(this.investWanOfIncome * 360 / 10000 * 100, 10000);
                } else if (this.investRateUnit == '月') {
                    this.investRate = this.approach(this.investWanOfIncome * 30 / 10000 * 100, 10000);
                } else if (this.investRateUnit == '日') {
                    this.investRate = this.approach(this.investWanOfIncome / 10000 * 100, 10000);
                }
            }
            this.countIncome();
        },
        switchCountMode() {
            if (this.investCountMode == '单利') {
                document.getElementById('investAgain').style.display = "none";
            } else if (this.investCountMode == '复利') {
                document.getElementById('investAgain').style.display = "";
            }
            this.countIncome();
        },
        handleScreen() {
            if (window.innerWidth <= 767) {
                if (investRate != null) {
                    this.$refs.investRate.querySelector('input').maxLength = 6;
                }
                if (investIncomeInterest != null) {
                    this.$refs.investIncomeInterest.querySelector('input').maxLength = 6;
                }
            }
            if (window.innerWidth > 767) {
                if (investRate != null) {
                    this.$refs.investRate.querySelector('input').maxLength = 12;
                }
                if (investIncomeInterest != null) {
                    this.$refs.investIncomeInterest.querySelector('input').maxLength = 12;
                }
            }
        }
    },
    mounted() {
        this.countWanOfIncome();
        this.switchCountMode();

        this.handleScreen();
        window.addEventListener("resize", this.handleScreen);
    },
    destroyed() {
        // 在组件生命周期结束的时候销毁。
        window.removeEventListener("resize", this.handleScreen);
    }
}
</script>

<style scoped>


@media screen and (min-width: 800px) {
    .random {
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        border: 1px dashed #cccccc;
    }

    .random-left {
        margin: 5px;
        border: 1px solid #000000;
        flex-basis: 67%;
    }

    .random-left .line {
        border-top: 1px solid #000000;
    }

    .random-left .about {
        margin: 10px;
    }

    .title {
        font-weight: 700;
        font-size: 32px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 80px;
        height: 80px;
    }

    .password-table {
        border-collapse: collapse;
        max-width: 450px;
        margin: 0 auto;
        margin-top: 10px;
        font-size: 12px;
    }

    .password-table td {
        border: 1px solid black;
        padding: 8px;
    }

    .random-right {
        flex-basis: 33%;
        margin: 5px;
    }

}

@media screen and (max-width: 799px) {
    .random {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .random-left {
        margin: 5px;
        flex-basis: 100%;
    }

    .title {
        font-weight: 700;
        font-size: 32px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 80px;
        height: 80px;
    }

    .password-table {
        border-collapse: collapse;
        max-width: 450px;
        margin: 0 auto;
        margin-top: 10px;
        font-size: 12px;
    }

    .password-table td {
        border: 1px solid black;
        padding: 8px;
    }

    .random-right {
        display: none;
    }
}
</style>
